.form-container {
  background-color: #f4f4f9;
  padding: 40px;
  border-radius: 12px;
  max-width: 1000px;
  margin: 50px 10px;
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.1);
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}

h2 {
  text-align: center;
  color: #2c3e50;
  font-size: 28px;
  margin: 0;
}

.form-row {
  display: flex;
  align-items: flex-start; /* Align inputs and button at the top */
  gap: 25px;
  margin-bottom: 20px;
}

.form-group {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.form-group label {
  font-size: 16px;
  color: #34495e;
  margin-bottom: 8px;
}

.form-input {
  width: 100%;
  padding: 10px;
  border: 2px solid #dfe6e9;
  border-radius: 8px;
  font-size: 16px;
  background-color: #fff;
  transition: all 0.3s ease;
}

.form-input:focus {
  border-color: #3498db;
  box-shadow: 0px 0px 8px rgba(52, 152, 219, 0.5);
  outline: none;
}

.input-error {
  border-color: #e74c3c;
}

.error-text {
  color: #e74c3c;
  font-size: 14px;
  margin-top: 5px;
  font-weight: bold;
}

.add-btn {
  background-color: #2ecc71;
  padding: 10px 20px;
  font-size: 16px;
  border: none;
  border-radius: 8px;
  color: white;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.3s ease;
}

.del-btn {
  background-color: #d50808;
  padding: 10px 20px;
  font-size: 16px;
  border: none;
  border-radius: 8px;
  color: white;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.3s ease;
}

.del-btn:hover {
  background-color: #e93535;
  transform: translateY(-2px);
}

.add-btn:hover {
  background-color: #27ae60;
  transform: translateY(-2px);
}

.submit-btn {
  padding: 12px 30px;
  background-color: #3498db;
  border: none;
  color: white;
  font-size: 18px;
  border-radius: 8px;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.3s ease;
  margin: 30px 0;
}

.submit-btn:hover {
  background-color: #2980b9;
  transform: translateY(-2px);
}

/* Center the Submit button */
.submit-btn-container {
  display: flex;
  justify-content: center;
}

@media screen and (max-width: 1000px) {
  .form-row {
    flex-direction: column;
    gap: 15px;
  }

  .form-container {
    padding: 20px;
  }
}
