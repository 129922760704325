.student-list-container {
    max-width: 800px;
    margin: 20px auto;
    padding: 20px;
    background-color: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .student-list-title {
    text-align: center;
    font-size: 24px;
    color: #333;
    margin-bottom: 20px;
  }
  
  .student-table {
    width: 100%;
    border-collapse: collapse;
    margin: 0 auto;
  }
  
  .student-table th,
  .student-table td {
    padding: 12px 15px;
    text-align: left;
    border-bottom: 1px solid #ddd;
  }
  
  .student-table th {
    background-color: #4caf50;
    color: white;
    font-weight: bold;
  }
  
  .student-table tr:nth-child(even) {
    background-color: #f2f2f2;
  }
  
  .student-table tr:hover {
    background-color: #ddd;
  }
  
  .edit-btn,
  .delete-btn {
    padding: 8px 12px;
    margin-right: 5px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .edit-btn {
    background-color: #007bff;
    color: white;
  }
  
  .edit-btn:hover {
    background-color: #0056b3;
  }
  
  .delete-btn {
    background-color: #f44336;
    color: white;
  }
  
  .delete-btn:hover {
    background-color: #d32f2f;
  }
  
  .no-data {
    text-align: center;
    color: #999;
    font-size: 18px;
    margin-top: 20px;
  }
  