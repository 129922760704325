/* Overlay for the popup */
.popup-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.6);
    display: flex;
    justify-content: flex-end;/*Align popup to the right */
    align-items: flex-start; /* Align popup to the top */
    
}

/* Popup container */
.popup-content {
    background: white;
    padding: 10px;
    border-radius: 8px;
    width: 320px;
    
    text-align: center;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    margin: 20px; /* Add margin from the edge of the screen */
}

/* Close button */
.close-btn {
    background-color: #02485c;
    color: white;
    border: none;
    padding: 10px 15px;
    border-radius: 5px;
    cursor: pointer;
    margin-top: 10px;
}

.close-btn:hover {
    background-color: #21a1f1;
}
.success-btn{
    background-color:green;
    color: white;
    border: none;
    padding: 10px 15px;
    border-radius: 5px;
    cursor: pointer;
    margin-top: 10px;
}
.success-btn:hover{
    background-color: green;
}
